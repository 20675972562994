import { getCompleteHomeLocation } from "@garantidos/utils"

const isBankVariant = (variant) => {
  if (typeof window !== "undefined" && window.document) {
    return document.location.pathname.includes(variant)
  }
  return false
}

const bankVariantLink = (link = "", variant = "personnalite") => {
  const completeLink = isBankVariant(variant) ? `/${variant}${link}` : link
  return completeLink
}

const getLogoUrl = (
  logoFileName = "logo-personnalite2.png",
  variant = "personnalite"
) => {
  const isVariant = isBankVariant(variant)
  if (!isVariant) return null
  if (typeof window === "undefined" || !window.document) return null
  const { protocol, host, pathname } = document.location
  const pathChunks = pathname.split("/")
  const personnaliteIndex = pathChunks.findIndex(
    (chunk) => chunk === "personnalite"
  )
  if (personnaliteIndex > -1) {
    pathChunks.splice(personnaliteIndex)
  }
  const imagePath = `${host}/${pathChunks.join(
    "/"
  )}/images/${logoFileName}`.replace(/\/\//gm, "/")
  const logoUrl = `${protocol}//${imagePath}`
  return logoUrl
}

const getStepsPrevUrl = () => {
  const { pathname } = window.location
  const chunks = pathname.split("/")
  if (chunks[chunks.length - 1] === "") {
    chunks.splice(chunks.length - 1)
  }
  const path = chunks.pop()
  const fullPath = getCompleteHomeLocation({ proposalRoute: path })
  return `/${fullPath}`
}

export { isBankVariant, bankVariantLink, getLogoUrl, getStepsPrevUrl }
