import { tokens } from "gatsby-env-variables"

import { saveCookieBarStylesToStorage } from "@garantidos/utils/cookieBar"
import datadog from "@garantidos/utils/js/datadog"
import { trackDefaultParameters } from "@garantidos/utils/tracking"
import appTrackers from "@garantidos/utils/tracking/appTrackers"

import configure from "@mobi/ds/configure"
import analytics from "@mobi/libraries/analytics"

import "styles/index.scss"

import { isBankVariant } from "./src/utils"

const variant = isBankVariant("personnalite") ? "personnalite" : "varejo"

configure({
  variant,
  originFonts: "extern",
  fonts: {
    ItauDisplay: [900],
    ItauText: [400, 700]
  },
  setStyleVariablesInHTML: false
})

Promise.resolve(import("@garantidos/utils/bootstrap"))

if (!window.analyticsData) {
  window.analyticsData = {}
}

analytics.setup({
  segmentId: tokens.SEGMENT,
  GTMId: tokens.GTMId,
  trackings: appTrackers,
  isSDKTrackings: true
})

trackDefaultParameters({
  ga4: {
    business: "pf",
    environment: "nao-logado",
    businessChannel: "consorcio"
  }
})

datadog.init()

export const onRouteUpdate = () => {
  saveCookieBarStylesToStorage()
}
